import classNames from '@sindresorhus/class-names';
import * as React from 'react';

import './styles.scss';

interface IProps {
  label: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}

export const Button = (
  props: IProps & React.HTMLAttributes<HTMLButtonElement>,
) => {
  const { label, className, ...buttonProps } = props;

  return (
    <button
      className={classNames('button', className)}
      {...buttonProps}
    >
      {label}
    </button>
  );
};
