import React, { FC } from "react";
import Helmet from "react-helmet";

export const MetaData: FC = () => (
  <Helmet>
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <meta name="theme-color" content="#000000" />

    <link rel="shortcut icon" href="/favicon.ico?v=1" />
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="/apple-touch-icon.png?v=1"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="/favicon-32x32.png?v=1"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="/favicon-16x16.png?v=1"
    />
    <link rel="manifest" href="/site.webmanifest?v=1" />
    <link
      rel="mask-icon"
      href="/safari-pinned-tab.svg?v=1"
      color="#1e1e1e "
    />
    <meta name="msapplication-TileColor" content="#1e1e1e" />
    <meta name="theme-color" content="#1e1e1e" />

    <title>Fahrschule Groß-Umstadt</title>
    <meta
      name="description"
      content="Die Fahrschule in Groß-Umstadt! Wir bieten Dir ein täglich geöffnetes Büro, Intensivkurse über das ganze Jahr, moderne Fahrzeuge und noch vieles mehr!"
    />

    <meta property="og:title" content="Fahrschule Groß-Umstadt" />
    <meta property="og:url" content="https://fahrschule-grossumstadt.de" />
    <meta
      property="og:description"
      content="Die Fahrschule in Groß-Umstadt! Wir bieten Dir ein täglich geöffnetes Büro, Intensivkurse über das ganze Jahr, moderne Fahrzeuge und noch vieles mehr!"
    />
  </Helmet>
);
