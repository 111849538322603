import * as React from "react";
import Link from 'gatsby-link';

import { Button } from "../../components/Button";

import Logo from "./logo.svg";

import "./styles.scss";

export const Header = () => {
  // const { user } = props;

  return (
    <div className="header d-flex justify-content-between">
      <Link to="/">
        <Logo className="header__logo" height="100%"/>
      </Link>

      <div className="header__info">
        <div>
          RUF AN -
          <a
            href="tel:+4960789304086"
            className="font-weight-bold text-main ml-5"
          >
            06078 9304086
          </a>
        </div>
        <a
          className="mt-5"
          href="http://www.fahrschulanmeldung.de/fahrschule-gross-umstadt-inh-nikola-andelkovic-gross-umstadt-2"
          target="_blank"
        >
          <Button label="Online anmelden!" />
        </a>
      </div>
    </div>
  );
};
